import React from "react";

interface DefaultButton {
  text: string;
  onPress: any;
  className?: string;
  disabled?: boolean;
}

export function DefaultButton(props: DefaultButton) {
  return (
    <div className={props.className}>
      <button
        onClick={props.onPress}
        disabled={props.disabled}
        className={`box-border w-full h-12 items-center justify-center ${
          props.disabled
            ? "bg-gray-400"
            : "bg-gray-900 hover:bg-gray-700 focus:outline-none active:bg-gray-500"
          } py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-xl text-white`}
      >
        {props.text}
      </button>
    </div>
  );
}

export function RedButton(props: DefaultButton) {
  return (
    <div className={props.className}>
      <button
        onClick={props.onPress}
        disabled={props.disabled}
        className={`box-border h-12 items-center justify-center ${
          props.disabled
            ? "bg-red-200"
            : "bg-red-600 hover:bg-red-700 focus:outline-none active:bg-gray-500"
          } py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-xl text-white`}
      >
        {props.text}
      </button>
    </div>
  );
}

export function OutlineButton(props: DefaultButton) {
  return (
    <div className={props.className}>
      <button
        onClick={props.onPress}
        disabled={props.disabled}
        className={`box-border h-12 items-center justify-center w-full ${
          props.disabled
            ? "bg-gray-100 text-gray-400"
            : "hover:bg-gray-100 text-gray-600 "
        } bg-white py-2 px-4 border border-1 border-gray-300 shadow-sm text-sm font-medium rounded-xl`}
      >
        {props.text}
      </button>
    </div>
  );
}

export function TextButton(props: DefaultButton) {
  return (
    <div className={props.className}>
      <button
        onClick={props.onPress}
        disabled={props.disabled}
        className={`box-border h-12 items-center justify-center ${
          props.disabled ? "text-gray-400" : "text-gray-900 hover:text-gray-700"
        } inline-flex self-center py-2 px-4 text-xs font-medium`}
      >
        {props.text}
      </button>
    </div>
  );
}

export function SmallButton(props: DefaultButton) {
  return (
    <div className={props.className}>
      <button
        onClick={props.onPress}
        disabled={props.disabled}
        className={`box-border min-h-7 items-center justify-center ${
          props.disabled
            ? "bg-gray-400"
            : "bg-gray-900 hover:bg-gray-700 active:bg-gray-500"
          } py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-xl text-white`}
      >
        {props.text}
      </button>
    </div>
  );
}

export function DefaultFullWidthButton(props: DefaultButton) {
  return (
    <div className={props.className}>
      <button
        onClick={props.onPress}
        disabled={props.disabled}
        className={`w-full box-border h-12 items-center justify-center ${
          props.disabled
            ? "bg-gray-400"
            : "bg-gray-900 hover:bg-gray-700 active:bg-gray-500"
        } py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-xl text-white `}
      >
        {props.text}
      </button>
    </div>
  );
}

export function FullWidthTextButton(props: DefaultButton) {
  return (
    <div className={props.className}>
      <button
        onClick={props.onPress}
        disabled={props.disabled}
        className={`w-full box-border h-12 items-center justify-center ${
          props.disabled ? "text-gray-400" : "text-gray-900 hover:text-gray-700"
        }   inline-flex self-center py-2 px-4 text-xs font-medium`}
      >
        {props.text}
      </button>
    </div>
  );
}

export function RedFullWidthButton(props: DefaultButton) {
  return (
    <div className={props.className}>
      <button
        onClick={props.onPress}
        disabled={props.disabled}
        className={`w-full box-border h-12 items-center justify-center ${
          props.disabled
            ? "bg-red-200"
            : "bg-red-600 hover:bg-red-700 focus:outline-none active:bg-gray-500"
          } py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-xl text-white`}
      >
        {props.text}
      </button>
    </div>
  );
}

export function ActivateButton(props: DefaultButton) {
  return (
    <div className={props.className}>
      <button
        onClick={props.onPress}
        disabled={props.disabled}
        className={`box-border h-12 items-center justify-center ${
          props.disabled
            ? "bg-green-200"
            : "bg-green-600 hover:bg-green-700"
        } inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-xl text-white `}
      >
        {props.text}
      </button>
    </div>
  );
}

interface IconButtonProps {
  icon: string;
  onPress: any;
  className?: string;
  disabled?: boolean;
}

export function RedRemoveButton(props: IconButtonProps) {
  return (
    <div className={props.className}>
      <button
        onClick={props.onPress}
        disabled={props.disabled}
        className={`box-border h-12 w-12 items-center justify-center ${
          props.disabled
            ? "bg-red-200"
            : "bg-red-600 hover:bg-red-700 focus:outline-none active:bg-gray-500"
          } border border-transparent shadow-sm text-sm font-medium rounded-xl text-white`}
      >
        X
      </button>
    </div>
  );
}

// interface RadioButtonProps {
//   text: string;
//   onPress: any;
//   complete?: boolean;
//   active: boolean;
//   className?: string;
// }

// export function RadioButton(props: RadioButtonProps) {
//   return (
//     <div className={props.className}>
//       <button
//         onClick={props.onPress}
//         className={`box-border h-12 flex flex-row items-center justify-between ${
//           props.active
//             ? "bg-gray-900 hover:bg-gray-700 text-white "
//             : "shadow-sm text-gray-900 bg-white hover:bg-gray-50"
//         }   inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-xl`}
//       >
//         {props.text}
//         {
//           props.complete !== undefined ? (
//             props.complete === true ?
//             <FontAwesomeIcon icon={"check"} className={`${props.active ? 'text-white' : 'text-gray-900'} ml-1`} /> :
//             <FontAwesomeIcon icon={"xmark"} className={`${props.active ? 'text-white' : 'text-gray-900'} ml-1`} />
//           ) : null
//         }
//       </button>
//     </div>
//   );
// }
