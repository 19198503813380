import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import './App.css';
import { DefaultButton, DefaultFullWidthButton, FullWidthTextButton, OutlineButton, RedButton, SmallButton, TextButton } from './components/Buttons';
import { DefaultInput, EmailInput, NumberInput, PasswordInput } from './components/Inputs';
import useScript from './lib/useScript';
import logoImage from './assets/branding/logo.png';
import axios from 'axios';
import { emailValidator } from './lib/emailValidator';
import blankImage from '../src/assets/blank-image.jpeg';
import { getConfig } from '@testing-library/react';

interface Customer {
  id: string;
  name: string;
  email: string;
  balance: number;
  customerImage: string | null;
  type: "points" | "stamps" | "savings" | "generic";
  stampsConfig: {
    stampsBeforeRedemption: number;
  } | null;
  savingsConfig: {
    freeProductsForCustomer: number[]
  } | null,
  passTemplateName: string | null;
}

interface TransactionInfo {
  total: number;
  products: {
    id: number;
    name: string;
    price: number;
  }[];
}

interface IntegrationConfig {
  stampsDiscountReasonId: number;
  pointsDiscountReasonId: number;
}

function App() {

  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  const [loginModal, setLoginModal] = useState<boolean>(false);

  const [loginInput, setLoginInput] = useState<{ email: string; password: string; pairingCode: string }>({
    email: '',
    password: '',
    pairingCode: '',
  });

  const [appToken, setAppToken] = useState<string>('');

  const [config, setConfig] = useState<IntegrationConfig | null>(null);

  const [transactionInfo, setTransactionInfo] = useState<TransactionInfo | null>(null);

  const [showScanPassModal, setShowScanPassModal] = useState<boolean>(false);

  const [customerResults, setCustomerResults] = useState<Customer[]>([]);
  const [resultsLoading, setResultsLoading] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

  const [userLoading, setUserLoading] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>('');
  const [scanInput, setScanInput] = useState<string>('');

  // other sections

  const [showUsePointsScreen, setShowUsePointsScreen] = useState<boolean>(false);
  const [showUseStampsScreen, setShowUseStampsScreen] = useState<boolean>(false);

  const [pointsToUse, setPointsToUse] = useState<number>(0);

  const [productsLoading, setProductsLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<{ id: number; name: string; price: number; }[]>([]);
  const [selectedStampsRedeemProduct, setSelectedStampsRedeemProduct] = useState<{ id: number; name: string; price: number; } | null>(null);

  useScript('https://cdn.eposnowhq.com/sdk/eposnow.till.sdk.js');

  useEffect(() => {
  
    (document as any).getElementById("epos-script").addEventListener('load', () => {
      // DTM is loaded
  
      console.log('epos-script loaded')

      const eposSDK = (window as any).EposNowTillSDK;

      console.log('eposSDK', eposSDK)

      eposSDK.TransactionPublisher.register(window);

      console.log('register', eposSDK)

      const { appToken } = parseQueryParams() as any;

      // TODO: if there is a transaction detail with a customer id, then we need to get the customer details and show the customer screen

      const transaction_data = new eposSDK.Transaction();

      console.log('transactionData', transaction_data)

      if (appToken) {
        verifyUser(appToken);
      } else {
        alert("An EPOS Now app token is required to use this app. Please contact your EPOS Now account manager for more information.");
      }
    })
  
  }, [])

  const verifyUser = async (token: string) => {

    if (!token) {
      alert('Could not verify user')
      return;
    }

    axios.get(process.env.REACT_APP_API_URL + 'epos-now/web-api/auth/verify-device', {
      headers: {
        Authorization: `${token}`
      }
    }).then((response: any) => {

      console.log('verifyUser response', response)

      // show the scan pass modal
      
      setShowScanPassModal(true);

      // Device is verified. Do nothing, as the app token will be used for all future requests

      getConfig(token);

    }).catch((error: any) => {

      console.log('verifyUser error', error)

      // Device is not verified. Show login modal

      setLoginModal(true);

    })
  }

  const getConfig = async (token: string) => {
    axios.get(process.env.REACT_APP_API_URL + 'epos-now/web-api/config', {
      headers: {
        Authorization: `${token}`
      }
    }).then((response: any) => {

      console.log('getConfig response', response)

      setConfig(response.data.config);

    }).catch((error: any) => {
      console.log('getConfig error', error)
      try {
        alert(error.response.data.message);
      }
      catch (e) {
        alert('Error getting config');
      }
    });
  }

  const loginFunction = () => {

    if (!loginInput.email || !emailValidator(loginInput.email) || !loginInput.password || !loginInput.pairingCode) {
      alert('Please enter an email, password and pairing code')
      return;
    }

    setLoginLoading(true);

    axios.post(process.env.REACT_APP_API_URL + 'epos-now/web-api/auth/authenticate', {
      email: loginInput.email,
      password: loginInput.password,
      pairingCode: loginInput.pairingCode
    }, {
      headers: {
        Authorization: `${appToken}`
      }
    }).then((response: any) => {

      console.log('response: ', response)

      setLoginLoading(false);
        
        // Login successful. Set app token and close login modal
        setLoginModal(false);
  
    }).catch((error: any) => {

      setLoginLoading(false);
      try {
        alert(error.response.data.message)
      }
      catch {
        alert('An error occurred')
      }
    });

  }

  const parseQueryParams = () => {

    console.log('search: ' , window.location.search)

    const split = window.location.search.split('&');

    console.log('split: ', split)

    const params = new URLSearchParams(window.location.search);

    const appToken = params.get('appToken');

    console.log('app token: ', appToken)

    const deviceID = params.get('deviceID');

    const locationID = params.get('locationID');

    const staffID = params.get('staffID');

    const source = params.get('source');

    const orderId = params.get('orderId');

    const transactionTotal = params.get('transactionTotal');

    const remainingBalance = params.get('remainingBalance');

    const customerId = params.get('customerId');

    const productIds = params.get('productIds');

    const products = params.get('products');

    const miscProducts = params.get('miscProducts');

    const searchParameter = params.get('searchParameter');
    
    if (!appToken) {
      alert('No app token provided');
      return;
    }

    console.log('setting app token: ', appToken)
    setAppToken(appToken);

    setTransactionInfo({
      total: parseFloat(transactionTotal || '0'),
      products: JSON.parse(products || '[]')
    });


    return {
      appToken,
      deviceID,
      locationID,
      staffID,
      source,
      orderId,
      transactionTotal,
      remainingBalance,
      customerId,
      productIds,
      products,
      miscProducts,
      searchParameter
    }
  }

  const scanPass = (scannedPass: string) => {

    console.log('scannedPass', scannedPass)

    if (!scannedPass) {
      return;
    }

    setShowScanPassModal(false);

    setUserLoading(true);

    axios.get(process.env.REACT_APP_API_URL + `epos-now/web-api/customers/get-customer-by-pass-id?passId=${scannedPass}`, {
      headers: {
        Authorization: `${appToken}`
      }
    }).then((response: any) => {

      const { customer } = response.data;

      setSelectedCustomer(customer)

      setUserLoading(false);

    }).catch((error: any) => {
      setUserLoading(false);
      try {
        alert(error.response.data.message)
      }
      catch {
        alert('An error occurred')
      }
    });
  }

  const searchCustomers = () => {
    if (!searchInput) {
      return;
    }

    console.log('appToken', appToken)

    console.log('searchInput', searchInput)

    setResultsLoading(true);

    axios.get(process.env.REACT_APP_API_URL + `epos-now/web-api/customers/search-customers?search=${searchInput}`, {
      headers: {
        Authorization: `${appToken}`
      }
    }).then((response: any) => {

      const { results } = response.data;

      console.log('results', results)

      setCustomerResults(results)

      setResultsLoading(false);

    }).catch((error: any) => {
      setResultsLoading(false);
      try {
        alert(error.response.data.message)
      }
      catch {
        alert('An error occurred')
      }
    });
  }

  const getProductsForStamps = () => {

    setProductsLoading(true);

    axios.get(process.env.REACT_APP_API_URL + `epos-now/web-api/config/stamps-products`, {
      headers: {
        Authorization: `${appToken}`
      }
    }).then((response: any) => {

      const { products } = response.data;

      setProducts(products)

      setProductsLoading(false);

    }).catch((error: any) => {
      setProductsLoading(false);
      try {
        alert(error.response.data.message)
      }
      catch {
        alert('An error occurred')
      }
    });
  }

  const usePointsTapped = () => {

    if (!selectedCustomer) {
      alert('Please select a customer')
      return;
    }

    if (selectedCustomer.balance < 1) {
      alert('Customer has no points')
      return;
    }

    if (!transactionInfo) {
      alert('No transaction info')
      return;
    }

    console.log('selected Customer: ', selectedCustomer)
    console.log('transactionInfo: ', transactionInfo)
      
    var eposSDK = (window as any).EposNowTillSDK;

    var transaction_data = new eposSDK.Transaction();

    

    // Assign customer to transaction

    transaction_data.TransactionDetails.push(new eposSDK.TransactionDetail("wpCustomerId", selectedCustomer.id));
    transaction_data.TransactionDetails.push(new eposSDK.TransactionDetail("Wallet Pass Points Used", "Points Used: " + pointsToUse + " Remaining Balance: " + (selectedCustomer.balance - pointsToUse)));
    
    // console.log('transaction_data', transaction_data)

    // Add discount

    transaction_data.Discount = new eposSDK.Discount(config?.pointsDiscountReasonId, (pointsToUse / 100));

    // return to till

    console.log('transaction_data', transaction_data)

    eposSDK.TransactionPublisher.publish(transaction_data);

    (window as any).top.postMessage(JSON.stringify(transaction_data), "*");

    console.log('sent')
  
  }

  const useStampsTapped = () => {
    console.log('selected Customer: ', selectedCustomer)
    console.log('transactionInfo: ', transactionInfo)
      
      if (!selectedCustomer) {
        alert('Please select a customer')
        return;
      }
  
      if (selectedCustomer.balance <= 0) {
        alert('Customer has no points')
        return;
      }

      if (transactionInfo === null) {
        alert('No transaction info')
        return;
      }
      
      var eposSDK = (window as any).EposNowTillSDK;

      var transaction_data = new eposSDK.Transaction();
  
      // Assign customer to transaction
  
      transaction_data.TransactionDetails.push(new eposSDK.TransactionDetail("wpCustomerId", selectedCustomer.id));

      console.log('transaction_data', transaction_data)

      transaction_data.Products.push(new eposSDK.Product(selectedStampsRedeemProduct, 1, 0, false));
  
      // return to till
  
      eposSDK.TransactionPublisher.publish(transaction_data);
  
      (window as any).top.postMessage(JSON.stringify(transaction_data), "*");
  
      console.log('sent')
  }

  const assignCustomerToTransaction = () => {

    if (!selectedCustomer) {
      return;
    }

    var eposSDK = (window as any).EposNowTillSDK;

    var transaction_data = new eposSDK.Transaction();

    // remove transaction detail with key wpCustomerId

    transaction_data.TransactionDetails = transaction_data.TransactionDetails.filter((transactionDetail: any) => transactionDetail.Name !== 'wpCustomerId');

    // Assign customer to transaction

    transaction_data.TransactionDetails.push(new eposSDK.TransactionDetail("wpCustomerId", selectedCustomer.id));

    console.log('transaction_data', transaction_data)

    // return to till

    eposSDK.TransactionPublisher.publish(transaction_data);

    (window as any).top.postMessage(JSON.stringify(transaction_data), "*");

    console.log('sent')

  }

  const removeCustomerFromTransaction = () => {
      
      var eposSDK = (window as any).EposNowTillSDK;
  
      var transaction_data = new eposSDK.Transaction();
  
      // Remove customer from transaction

      console.log(transaction_data.TransactionDetails)
  
      // remove element with key "wpCustomerId"

      transaction_data.TransactionDetails = [];

      console.log(transaction_data.TransactionDetails)

      // remove tender with key "19297"

      transaction_data.Discount = null;

      // return to till
  
      eposSDK.TransactionPublisher.publish(transaction_data);
  
      (window as any).top.postMessage(JSON.stringify(transaction_data), "*");
  
      console.log('sent')
  
  }

  const updatePointsToUse = (e: any) => {

    console.log('update')

    if (!selectedCustomer) {
      return;
    }

    if (!transactionInfo) {
      return;
    }
    
    let newValue = (pointsToUse * 10 + e);

    if (newValue > selectedCustomer.balance) {
      newValue = selectedCustomer.balance;
    }

    console.log('transactionInfo', transactionInfo)

    if (newValue > (transactionInfo.total * 100)) {
      newValue = (transactionInfo.total * 100);
    }

    setPointsToUse(Math.round(newValue));

    return;

  }
  return (
    <>
      <div className="h-screen bg-gray-100">
        {/* Tailwind page with a title and a paragraph text in the center */}
        <header className="bg-white shadow">
          <div className="flex flex-row justify-between items-center h-20 p-5">
            <div className="flex flex-row items-center">
              {
                showUsePointsScreen ? (
                  <DefaultButton onPress={() => setShowUsePointsScreen(false)} text="Back" className="mr-5" />
                ) : null
              }
              {
                showUseStampsScreen ? (
                  <DefaultButton onPress={() => setShowUseStampsScreen(false)} text="Back" className="mr-5" />
                ) : null
              }
              <img src={logoImage} alt="Wallet Pass" className="h-7" />
            </div> 
            <TextButton text="Help" onPress={() => {}} />
          </div>
        </header>
        {
          showUseStampsScreen && selectedCustomer && selectedCustomer.stampsConfig ? (
            <div className="flex flex-row w-full">
              <div className="flex flex-col w-1/2 p-10">
                <h2 className="text-2xl font-bold mb-2">{ selectedCustomer.name }</h2>
                <p className="text-sm mb-5">{ selectedCustomer.email }</p>
                <p className="text-4xl">Balance: {selectedCustomer.balance}/{selectedCustomer.stampsConfig?.stampsBeforeRedemption}</p>
              </div>
              <div className="flex flex-col w-1/2 p-10">
                {
                  productsLoading ? (
                    <div className="flex flex-col items-center justify-center w-full pt-5">
                      <p>Loading...</p>
                    </div>
                  ) : 
                    <>
                      {
                        products.length > 0 ? (
                          <>
                            <div className="flex flex-col w-full h-full p-5 gap-2 overflow-scroll">
                              {
                                products.map((product: any, index: number) => {
                                  return (
                                    <div className={
                                      `flex flex-row items-center w-full p-5 bg-white rounded-md shadow-md cursor-pointer ${ selectedStampsRedeemProduct === product.id ? 'border-2 border-green-500' : '' }`
                                    } key={index} onClick={() => setSelectedStampsRedeemProduct(product.id)}>
                                      {/* Customer result card */}
                                      <div className="flex flex-row w-full h-full justify-between items-center">
                                        <div className="flex flex-col items-start">
                                          <h2 className="text-xl font-bold">{ product.name }</h2>
                                          <p className="text-sm">£{ product.price.toFixed(2) }</p>
                                        </div>
                                        
                                        <div className="flex flex-row items-center gap-2">
                                          {
                                            selectedStampsRedeemProduct === product.id ? (
                                              <SmallButton text="Add to Order" onPress={useStampsTapped} />
                                            ) : null
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </>
                        )
                        :
                        <div className="flex flex-col items-center justify-center w-full pt-5">
                          <p>No products found</p>
                        </div>
                      }
                    </>
                }
              </div>
            </div>
          ) : null
        }
        {
          showUsePointsScreen && selectedCustomer && transactionInfo ? (
            <div className="flex flex-row w-full">
              <div className="flex flex-col w-1/2 p-10">
                <h2 className="text-2xl font-bold mb-2">{ selectedCustomer.name }</h2>
                <p className="text-sm mb-5">{ selectedCustomer.email }</p>
                <p className="text-4xl">Balance: {selectedCustomer.balance} (£{(selectedCustomer.balance/100).toFixed(2)})</p>
                <p className="text-md pt-10">Transaction Total Value: £{transactionInfo?.total.toFixed(2)}</p>
                <div className="w-full h-20 bg-white flex items-center justify-center mt-28 rounded-md">
                  <p className="text-2xl font-semi-bold">Left to Pay: £{( ((transactionInfo.total * 100) - pointsToUse) / 100 ).toFixed(2)}</p>
                </div>
              </div>
              <div className="flex flex-col w-1/2 p-10">
                <p className="text-2xl font-bold mb-5">How many points would you like to use?</p>
                <NumberInput className="w-full mb-5" value={pointsToUse.toString()} />
                <div className="flex flex-col gap-5">
                  {/* create a number pad 0-9 */}
                  <div className="flex flex-row justify-between gap-5">
                    <OutlineButton className="w-full" text="1" onPress={() => updatePointsToUse(1)} />
                    <OutlineButton className="w-full" text="2" onPress={() => updatePointsToUse(2)} />
                    <OutlineButton className="w-full" text="3" onPress={() => updatePointsToUse(3)} />
                  </div>
                  <div className="flex flex-row justify-between gap-5">
                    <OutlineButton className="w-full" text="4" onPress={() => updatePointsToUse(4)} />
                    <OutlineButton className="w-full" text="5" onPress={() => updatePointsToUse(5)} />
                    <OutlineButton className="w-full" text="6" onPress={() => updatePointsToUse(6)} />
                  </div>
                  <div className="flex flex-row justify-between gap-5">
                    <OutlineButton className="w-full" text="7" onPress={() => updatePointsToUse(7)} />
                    <OutlineButton className="w-full" text="8" onPress={() => updatePointsToUse(8)} />
                    <OutlineButton className="w-full" text="9" onPress={() => updatePointsToUse(9)} />
                  </div>
                  <div className="flex flex-row justify-between gap-5">
                    <OutlineButton className="w-full" text="CLEAR" onPress={() => setPointsToUse(0)} />
                    <OutlineButton className="w-full" text="0" onPress={() => updatePointsToUse(0)} />
                    <DefaultButton className="w-full" text={"MAX (" + Math.round( selectedCustomer.balance < (transactionInfo.total * 100) ? selectedCustomer.balance : (transactionInfo.total * 100) ) + ")"} onPress={() => {
                      if (selectedCustomer.balance >= (transactionInfo.total * 100)) {
                        setPointsToUse(Math.round(transactionInfo.total * 100));
                      } else {
                        setPointsToUse(Math.round(selectedCustomer.balance));
                      }
                    }} />
                  </div>
                  <DefaultFullWidthButton text="Use Points" onPress={usePointsTapped} />
                </div>
              </div>
            </div>
          ) : null
        }
        {
          !showUsePointsScreen && !showUseStampsScreen ? (
            <div className="flex flex-row w-full">
              <div className="flex flex-col w-1/2 h-full">
                <div className="flex flex-col items-center w-full">
                  <DefaultFullWidthButton text="Scan Pass" onPress={() => { setScanInput(''); setShowScanPassModal(true)}} className="flex w-full p-5" />
                  <p className="">OR</p>
                  <div className="flex flex-row items-center w-full p-5 gap-2">
                    <DefaultInput className="w-full h-12" placeholder="Search Customers..." onChangeText={(change: string) => {
                      setSearchInput(change)
                    }} />
                    <DefaultButton text="Search" onPress={searchCustomers} />
                  </div>
                </div>
                {
                  resultsLoading ? (
                    <div className="flex flex-col items-center justify-center w-full pt-5">
                      <p>Loading...</p>
                    </div>
                  ) : (
                    <>
                    {
                      customerResults.length > 0 ? (
                        <>
                          {/* List of customer results */}
                          <div className="flex flex-col w-full h-full p-5 gap-2 overflow-scroll">
                            {
                              customerResults.map((customer, index) => {
                                return (
                                  <div className="flex flex-row items-center w-full p-5 bg-white rounded-md shadow-md">
                                    {/* Customer result card */}
                                    <div className="flex flex-row w-full h-full justify-between items-center">
                                      <div className="flex flex-col items-start">
                                        <h2 className="text-xl font-bold">{ customer.name }</h2>
                                        <p className="text-sm">{ customer.email }</p>
                                        {
                                          customer.type === 'points' ? (
                                            <p className="text-md font-bold">Points: {customer.balance} (£{(customer.balance/100).toFixed(2)})</p>
                                          ) : null
                                        }
                                        {
                                          customer.type === 'stamps' ? (
                                            <p className="text-md font-bold">Stamps: {customer.balance}/{customer.stampsConfig?.stampsBeforeRedemption}</p>
                                          ) : null
                                        }
                                        {
                                          customer.type === 'savings' ? (
                                            <p className="text-md font-bold">Savings: £{(customer.balance/100).toFixed(2)}</p>
                                          ) : null
                                        }
                                      </div>
                                      <div className="flex flex-row items-center gap-2">
                                        <SmallButton text="Select" onPress={() => setSelectedCustomer(customer)} />
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </>
                      ) : (
                        <div className="flex flex-col items-center justify-center w-full pt-5">
                          <p>No results found</p>
                        </div>
                      )
                    }
                    </>
                  )
                }
              </div>
              <div className="flex flex-col w-1/2 h-auto items-center pt-20">
                {
                  userLoading ? (
                    <div className="flex flex-col items-center justify-center w-full pt-5">
                      <p>Loading...</p>
                    </div>
                  ) : (
                    <>
                      {
                        selectedCustomer ? (
                          <>
                            <img src={selectedCustomer.customerImage ? selectedCustomer.customerImage : blankImage} alt="EposNow Logo" className="w-52 h-52 object-cover rounded-full mb-2" />
                            <h2 className="text-2xl font-bold mb-2">{ selectedCustomer.name }</h2>
                            <p className="text-sm mb-5">{ selectedCustomer.email }</p>
                            {
                              selectedCustomer.type === 'points' ? (
                                <p className="text-4xl">Points: {selectedCustomer.balance} (£{(selectedCustomer.balance/100).toFixed(2)})</p>
                              ) : null
                            }
                            {
                              selectedCustomer.type === 'stamps' ? (
                                <p className="text-4xl">Stamps: {selectedCustomer.balance}/{selectedCustomer.stampsConfig?.stampsBeforeRedemption}</p>
                              ) : null
                            }
                            {
                              selectedCustomer.type === 'savings' ? (
                                <p className="text-4xl">Savings: £{(selectedCustomer.balance/100).toFixed(2)}</p>
                              ) : null
                            }
                            <div className="flex flex-row gap-2 mt-20">
                              {
                                selectedCustomer.type === 'points' ? (
                                  <>
                                    {
                                      transactionInfo && transactionInfo.total > 0 && selectedCustomer.balance > transactionInfo.total * 100 ? (
                                        <OutlineButton text="Use Points" onPress={() => setShowUsePointsScreen(true)} />
                                      ) : null
                                    }
                                    <DefaultButton text="Collect Points" onPress={assignCustomerToTransaction} />
                                  </>
                                ) : null
                              }
                              {
                                selectedCustomer.type === 'stamps' ? (
                                  <>
                                    {
                                      selectedCustomer.balance <= (selectedCustomer.stampsConfig?.stampsBeforeRedemption || 0) ? (
                                        <OutlineButton text="Use Stamps" disabled={selectedCustomer.stampsConfig?.stampsBeforeRedemption !== selectedCustomer.balance} onPress={()=> {
                                          getProductsForStamps();
                                          setShowUseStampsScreen(true)
                                        }} />
                                      ) : null
                                    }
                                    {
                                      selectedCustomer.balance < (selectedCustomer.stampsConfig?.stampsBeforeRedemption || 0) ? (
                                        <DefaultButton text="Collect Stamp" onPress={assignCustomerToTransaction} />
                                      ) : null
                                    }
                                  </>
                                ) : null
                              }
                              {
                                selectedCustomer.type === 'savings' ? (
                                  <>
                                    <DefaultButton text="Add Customer to Transaction" onPress={assignCustomerToTransaction} />
                                  </>
                                ) : null
                              }
                            </div>
                            <RedButton className="mt-5" text="Deselect Customer" onPress={removeCustomerFromTransaction} />
                          </> ) : (
                            <div className="flex flex-col items-center justify-center w-full h-full pt-5">
                              <p className="text-2xl text-gray-600 font-bold">No customer selected</p>
                            </div>
                          )
                      }
                    </>
                  )
                }
                
              </div>
            </div>
          ) : null
        }
      </div>
      {
        showScanPassModal ? (
          <div className="fixed inset-0 z-40 flex items-center justify-center">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
              <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="max-w-lg w-full px-4 py-3">
                    <div className="flex flex-col justify-between items-center bg-white rounded-lg w-full p-5 drop-shadow">
                      <div className="flex flex-col">
                        <h2 className="text-xl font-semibold mb-3">Scan a Wallet Pass</h2>
                        <p className="text-gray-600">
                          Scan a wallet pass to add points to a customer's account.
                        </p>
                        {/* TODO: Image */}
                        <input type="text" autoFocus={true} onKeyUp={(key: any) => {
                          if (key.code === 'Enter') {
                            scanPass(scanInput)
                          }
                          return;
                        }} onChange={(change: any) => { setScanInput(change.target.value) }} placeholder="Scan a Wallet Pass" className="w-full h-12 mt-5 p-2 border border-gray-300 rounded-md" />
                        <div className="flex flex-row justify-end mt-5 gap-2">
                          <OutlineButton text={"I want to search customers"} onPress={() => setShowScanPassModal(false)} />
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        ) : null
      }
      {
        loginModal ? (
          <div className="fixed inset-0 z-40 flex items-center justify-center">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25">
              <div className="absolute inset-0 bg-gray-500 bg-opacity-25">
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="max-w-lg w-full px-4 py-3">
                    <div className="flex flex-col justify-between items-center bg-white rounded-lg w-full p-5 drop-shadow">
                      <div className="flex flex-col">
                        <h2 className="text-xl font-semibold mb-3">Sign in to your Wallet Pass Account.</h2>
                        <p className="text-gray-600 mb-5">
                          We need to sign you in to your Wallet Pass account to use this plugin. You will only have to do this once.
                        </p>
                        {/* TODO: Image */}
                        <EmailInput className="mb-2" label="Email" placeholder='Enter your email address' value={loginInput.email} onChangeText={(value: string) => setLoginInput({
                          ...loginInput,
                          email: value
                        })} />
                        <PasswordInput className="mb-2" label="Password" placeholder='********' value={loginInput.password} onChangeText={(value: string) => setLoginInput({
                          ...loginInput,
                          password: value
                        })} />
                        <DefaultInput className="mb-5" label="Pairing Code" placeholder='123456' value={loginInput.pairingCode} onChangeText={(value: string) => setLoginInput({
                          ...loginInput,
                          pairingCode: value
                        })} />
                        <DefaultFullWidthButton className="w-full" text={loginLoading ? "Authenticating..." : "Authenticate"} onPress={loginFunction} disabled={!loginInput.email || !emailValidator(loginInput.email) || !loginInput.password || !loginInput.pairingCode || loginLoading} />
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        ) : null
      }
    </>
  );
}

export default App;
